import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/app/node_modules/@mantine/core/esm/components/Breadcrumbs/Breadcrumbs.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/app/node_modules/@mantine/core/esm/components/Image/Image.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/app/node_modules/@mantine/core/esm/components/Tabs/Tabs.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TabsList"] */ "/app/node_modules/@mantine/core/esm/components/Tabs/TabsList/TabsList.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TabsPanel"] */ "/app/node_modules/@mantine/core/esm/components/Tabs/TabsPanel/TabsPanel.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TabsTab"] */ "/app/node_modules/@mantine/core/esm/components/Tabs/TabsTab/TabsTab.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["ContactForm"] */ "/app/src/components/ContactForm/ContactForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/contentful-components/ContactInfoSection/LeafletMap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactListClient"] */ "/app/src/contentful-components/ContactPersonInfoCardSection/ContactListClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationListClient"] */ "/app/src/contentful-components/NavigationList/NavigationListClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsCarousel"] */ "/app/src/contentful-components/NewsCarouselSection/NewsCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TestimonialCarousel"] */ "/app/src/contentful-components/TestimonialCarouselSection/TestimonialCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/imageLoader.js");
